<template>
  <div>
    <div class="box-top">
      <el-button ref="newForm" @click="newForm">新建</el-button>

      <el-dialog title="编辑信息" :visible.sync="dialogFormVisible">
        <el-form :model="form">
          <el-form-item label="标题" :label-width="formLabelWidth">
            <el-input v-model="form.name" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="描述" :label-width="formLabelWidth">
            <el-input type="textarea" v-model="form.desc"></el-input>
          </el-form-item>
          <el-form-item
            label="宣传图"
            :label-width="formLabelWidth"
            v-if="form.imageUrl"
          >
            <img
              class="dialog-pic"
              src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"
            />
          </el-form-item>
          <el-form-item label="宣传图" :label-width="formLabelWidth" v-else>
            <el-upload
              action="https://jsonplaceholder.typicode.com/posts/"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
          </el-form-item>

          <el-form-item label="视频地址" :label-width="formLabelWidth">
            <el-input v-model="form.videoUrl" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="官网地址" :label-width="formLabelWidth">
            <el-input v-model="form.officialAddr"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitEdit(form)">提 交</el-button>
        </div>
      </el-dialog>
    </div>
    <div>
      <el-table :data="tableData" border>
        <el-table-column prop="name" label="标题" width="180">
        </el-table-column>
        <el-table-column prop="desc" label="描述" width="180">
        </el-table-column>
        <el-table-column prop="imageUrl" label="宣传图">
          <template v-slot="scope">
            <img :src="scope.row.imageUrl" class="table-img" />
          </template>
        </el-table-column>
        <el-table-column prop="videoUrl" label="视频地址" width="180">
        </el-table-column>
        <el-table-column prop="officialAddr" label="官网地址" width="180">
        </el-table-column>
        <el-table-column
          prop="status"
          label="状态"
          :filters="config.tags"
          :filter-method="filterTag"
          width="180"
        >
          <template v-slot="scope">
            <el-tag
              disable-transitions
              :type="config.status[scope.row.status].type"
            >
              {{ config.status[scope.row.status].name }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column fixed="right" prop="status" label="操作" width="100">
          <template v-slot="scope">
            <div>
              <div v-if="scope.row.status == 'Promoting'">
                <el-button
                  ref="PromotingEdit"
                  type="text"
                  size="small"
                  @click="edit(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  @click="PromotingRevoke(scope.row)"
                  type="text"
                  size="small"
                  >下架</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  @click="PromotingApply(scope.row)"
                  >首页推广</el-button
                >
              </div>
              <div v-else-if="scope.row.status == 'Reviewing'">
                <el-button
                  type="text"
                  size="small"
                  @click="ReviewingRevoke(scope.row)"
                  >撤销审核</el-button
                >
              </div>
              <div v-else-if="scope.row.status == 'HomePromoting'">
                <el-button
                  type="text"
                  size="small"
                  @click="HomePromotingRevoke(scope.row)"
                  >撤销首页推广</el-button
                >
              </div>
              <div v-else-if="scope.row.status == 'Unreviewed'">
                <el-button
                  ref="UnreviewedEdit"
                  type="text"
                  size="small"
                  @click="edit(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  @click="UnreviewedDelete(scope.row)"
                  type="text"
                  size="small"
                  >删除</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  @click="UnreviewedApply(scope.row)"
                  >申请审核</el-button
                >
              </div>
              <div v-else-if="scope.row.status == 'Approved'">
                <el-button
                  type="text"
                  size="small"
                  @click="ApprovedApply(scope.row)"
                  >申请推广</el-button
                >
              </div>
              <div v-else-if="scope.row.status == 'PromotionReviewing'">
                <el-button
                  type="text"
                  size="small"
                  @click="PromotionReviewingRevoke(scope.row)"
                  >撤销推广审核</el-button
                >
              </div>
              <div v-else></div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [
        {
          name: 'name',
          desc: 'desc描述',
          imageUrl:
            'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
          videoUrl: 'http://xxx视频地址',
          officialAddr: 'http://xxx官网地址',
          status: 'Promoting',
        },
        {
          name: 'name',
          desc: 'desc描述',
          imageUrl:
            'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
          videoUrl: 'http://xxx视频地址',
          officialAddr: 'http://xxx官网地址',
          status: 'Reviewing',
        },
        {
          name: 'name',
          desc: 'desc描述',
          imageUrl:
            'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
          videoUrl: 'http://xxx视频地址',
          officialAddr: 'http://xxx官网地址',
          status: 'HomePromoting',
        },
        {
          name: 'name',
          desc: 'desc描述',
          imageUrl:
            'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
          videoUrl: 'http://xxx视频地址',
          officialAddr: 'http://xxx官网地址',
          status: 'Unreviewed',
        },
        {
          name: 'name',
          desc: 'desc描述',
          imageUrl:
            'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
          videoUrl: 'http://xxx视频地址',
          officialAddr: 'http://xxx官网地址',
          status: 'Approved',
        },
        {
          name: 'name',
          desc: 'desc描述',
          imageUrl:
            'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
          videoUrl: 'http://xxx视频地址',
          officialAddr: 'http://xxx官网地址',
          status: 'PromotionReviewing',
        },
      ],
      dialogFormVisible: false,
      form: {
        name: '',
        desc: '',
        imageUrl: '',
        videoUrl: '',
        officialAddr: '',
        status: '',
      },
      formLabelWidth: '120px',
      dialogImageUrl: '',
      dialogVisible: false,
      config: {
        status: {
          Unreviewed: {
            index: 0,
            name: '未审核',
            type: 'info',
          },
          Reviewing: {
            index: 1,
            name: '审核中',
            type: 'info',
          },
          Approved: {
            index: 2,
            name: '审核通过',
            type: 'warning',
          },
          PromotionReviewing: {
            index: 3,
            name: '推广审核中',
            type: 'warning',
          },
          Promoting: {
            index: 4,
            name: '推广中',
            type: 'success',
          },
          HomePromoting: {
            index: 5,
            name: '首页推广中',
            type: 'success',
          },
        },
        // Promoting 推广中,Reviewing 审核中,Unreviewed 未审核,Approved 审核通过,
        // PromotionReviewing 推广审核中,HomePromoting 首页推广
        tags: [
          { text: '推广中', value: 'Promoting' },
          { text: '审核中', value: 'Reviewing' },
          { text: '未审核', value: 'Unreviewed' },
          { text: '审核通过', value: 'Approved' },
          { text: '推广审核中', value: 'PromotionReviewing' },
          { text: '首页推广中', value: 'HomePromoting' },
        ],
      },
    };
  },
  methods: {
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    filterTag(value, row) {
      return row.status === value;
    },
    handleClick(row) {
      console.log(row);
    },
    newForm() {
      Object.assign(this.$data.form, this.$options.data().form);
      this.dialogFormVisible = true;
    },
    edit(row) {
      Object.assign(this.form, row);
      this.dialogFormVisible = true;
    },
    UnreviewedDelete(row) {
      // 删除请求
      console.log('UnreviewedDelete', row);
    },
    PromotingRevoke(row) {
      // 下架请求
      console.log('PromotingRevoke', row);
    },
    HomePromotingRevoke(row) {
      // 撤销首页推广请求
      console.log('HomePromotingRevoke', row);
    },
    submitEdit() {
      // 提交编辑请求
      this.dialogFormVisible = false;
    },
    ReviewingRevoke(row) {
      // 撤销审核请求
      console.log('ReviewingRevoke', row);
    },
    PromotionReviewingRevoke(row) {
      // 撤销推广审核请求
      console.log('PromotionReviewingRevoke', row);
    },
    UnreviewedApply(row) {
      // 申请审核请求
      console.log('UnreviewedApply', row);
    },
    ApprovedApply(row) {
      // 申请推广请求
      console.log('ApprovedApply', row);
    },
    PromotingApply(row) {
      // 首页推广请求
      console.log('PromotingApply', row);
    },
  },
};
</script>

<style scoped>
.box-top {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin: 16px 0;
}
.table-img {
  width: 60px;
  height: 60px;
}
.dialog-pic {
  width: 160px;
  height: 160px;
}
</style>
